import React from 'react'

import '../styles/app.css'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Sms from './Sms'
import UserContext from '../contexts/UserContext'

const App = () => {
  return (
        <UserContext>
            <Router>
                <Switch>
                    <Route path="/:accesstoken" component={Sms} />
                    <Route path="/" component={Sms} />
                </Switch>
            </Router>
        </UserContext>
  )
}

export default App
