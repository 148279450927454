class Resource {
  public readonly titleReactuInput = 'Por favor, insira o código recebido:'
  public readonly invalidReactCodeInputStyle = 'invalid-react-code-input'
  public readonly successReactCodeInputStyle = 'success-react-code-input'
  public readonly invalidReactCodeInputMessage = 'O código está incorreto.'
  public readonly invalidReactCodeInputExpireMessage = 'O código enviado expirou.'
  public readonly credentialAlreadyInUse = 'O número de telefone já está associado a outra conta.'
  public readonly userAlreadyLinked = 'Usuário já vinculado ao número de celular'
  public readonly internalServerError = 'Ops... Sistema indisponível, favor contatar o suporte.'
  public readonly cancelButton = 'Cancelar'
  public readonly emailButton = 'Validar por e-mail'
  public readonly successButton = 'Continuar'
  public readonly resendButton = 'Enviar novo código'
  public readonly smsDisclaimer = 'Enviamos um código via SMS para seu celular com \n final'
  public readonly timerDescription = 'Tempo de validade do código:'
  public readonly smsTitle = 'Quase lá!'
}

export default new Resource() as Resource
