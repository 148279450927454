import { Alert } from '@material-ui/lab'
import React, { memo } from 'react'
import Resource from '../common/Resource'

interface AlertFailFlowProps {
  showErrorAlert: boolean
}

const AlertFailFlow = (props: AlertFailFlowProps) => {
  return (
    <div hidden={!props.showErrorAlert} className="notification">
      <Alert severity="error">{Resource.internalServerError}</Alert>
    </div>
  )
}

export default memo(AlertFailFlow)
