import { Alert } from '@material-ui/lab'
import React, { memo } from 'react'
import Resource from '../common/Resource'

interface AlertUSerAlreadyLinkedProps {
  userAlreadyLinked: boolean
}

const AlertUSerAlreadyLinked = (props: AlertUSerAlreadyLinkedProps) => {
  return (
    <div hidden={!props.userAlreadyLinked} className="notification">
      <Alert severity="warning">
        {Resource.userAlreadyLinked}
      </Alert>
    </div>
  )
}
export default memo(AlertUSerAlreadyLinked)
