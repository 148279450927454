import { Button } from '@material-ui/core'
import React, { memo } from 'react'

import Resource from '../common/Resource'

interface EmailButtonProps {
  onClick: any
}

const EmailButton = (props: EmailButtonProps) => {
  return (
    <div className="email">
      <Button color="primary" className="email-button" onClick={props.onClick} >
        {Resource.emailButton}
      </Button>
    </div>
  )
}

export default memo(EmailButton)
