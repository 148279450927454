import { Container, Grid } from '@material-ui/core'
import React, { useEffect, useState, useContext } from 'react'

import 'firebase/auth'
import 'firebase/firestore'

import { FirebaseCode } from '../common/FirebaseCode'
import Resource from '../common/Resource'

import { UserContext } from '../contexts/UserContext'

import FirebaseService from '../services/FirebaseService'
import LoginService from '../services/LoginService'
import CacheService from '../services/CacheService'
import SmsConnectService from '../services/SmsConnectService'
import BusService from '../services/BusService'

import IUserModel from '../models/IUserModel'

import Title from '../components/Title'
import AlertFailFlow from '../components/AlertFailFlow'
import AlertUserAlreadyLinked from '../components/AlertUserAlreadyLinked'
import CancelButton from '../components/CancelButton'
import ResendButton from '../components/ResendButton'
import SmsBox from '../components/SmsBox'
import SmsDisclaimer from '../components/SmsDisclaimer'
import Spinner from '../components/Spinner'
import Recaptcha from '../components/Recaptcha'
import SuccessButton from '../components/SuccessButton'
import EmailButton from '../components/EmailButton'

const Sms = (props: any) => {
  const params = props.match.params

  const context = useContext(UserContext)
  const [showCircleProgressReady, setShowCircleProgressReady] = useState(true)
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [showResendButton, setShowResendButton] = useState(false)
  const [showCancelButton, setShowCancelButton] = useState(false)
  const [showSuccessButton, setShowSuccessButton] = useState(false)

  const getCurrentUser = () => {
    return { ...context.user }
  }

  const getRecaptcha = () => {
    return FirebaseService.recaptchaVerifier(() => {
      const flags = getCurrentUser()
      flags.recaptchaChecked = true
      context.setFlags(flags)
    }, 'recaptcha-container')
  }

  const onCallbackError = (error: any) => {
    console.error(error)
    setShowErrorAlert(true)
  }

  const onShowResendButton = () => {
    setShowResendButton(true)
  }

  const onVerifyLoginProvider = () => {
    LoginService.verify(context.user.accessToken)
      .then((validated) => {
        const flags = getCurrentUser()
        flags.isSmsValidated = validated
        context.setFlags(flags)
        BusService.onDispatchSuccessEvent()
        setShowSuccessButton(true)
      })
      .catch((error) => {
        onCallbackError(error)
      })
  }

  const onSuccessLinkPhone = (result: any) => {
    setShowCircleProgressReady(false)
    context.confirmationResult(result)
  }

  const onFailLinkPhone = (error: any) => {
    if (error.code === FirebaseCode.PROVIDER_ALREADY_LINKED) {
      const flags = getCurrentUser()
      flags.userAlreadyLinked = true
      context.setFlags(flags)
      setShowCircleProgressReady(false)
    } else {
      onCallbackError(error)
    }
  }

  const onResend = () => {
    FirebaseService.signOut(onFailLinkPhone)
  }

  const onCancel = () => {
    BusService.onDispatchCancelEvent()
  }

  const onShowCancelButton = () => {
    setShowCancelButton(true)
  }

  const onLinkWithPhoneNumber = (
    userCredential: any,
    userInfo: IUserModel,
    recaptchaVerifier: any
  ) => {
    FirebaseService.linkWithPhoneNumber(
      userCredential,
      userInfo.homePhone,
      recaptchaVerifier
    )
      ?.then((result: any) => {
        onSuccessLinkPhone(result)
      })
      .catch((error: any) => {
        onFailLinkPhone(error)
      })
  }

  const loadCredential = (userInfo: IUserModel) => {
    SmsConnectService.get(userInfo.accessToken).then((customToken) => {
      FirebaseService.loadCredential(customToken)
        .then((userCredential) => {
          setShowCircleProgressReady(false)
          onLinkWithPhoneNumber(userCredential, userInfo, getRecaptcha())
        })
        .catch((error) => {
          onCallbackError(error)
        })
    }).catch((error) => {
      onCallbackError(error)
    })
  }

  const loadUserInfo = (callback: any) => {
    const userCached = CacheService.get()
    if (userCached?.accessToken !== params.accesstoken) {
      LoginService.get(params.accesstoken)
        .then((userInfo) => {
          userInfo.accessToken = params.accesstoken
          userInfo.isSmsExpired = false
          userInfo.isSmsValidated = false
          userInfo.userAlreadyLinked = false
          CacheService.set(userInfo)
          context.set(userInfo)
          callback(userInfo)
        })
        .catch((error) => {
          onCallbackError(error)
        })
    } else {
      userCached.isSmsExpired = false
      userCached.isSmsValidated = false
      userCached.userAlreadyLinked = false
      context.set(userCached)
      callback(userCached)
    }
  }
  const onSuccess = () => {
    console.log('Success sms verify')
  }
  const onVerifyEmail = () => {
    console.log('Verify email button clicked!')
  }

  useEffect(() => {
    if (
      showCircleProgressReady &&
      !!params.accesstoken &&
      !context.user.homePhone &&
      !showErrorAlert
    ) {
      loadUserInfo(loadCredential)
    }
  }, [])

  return (
    <Container maxWidth="sm">
    <Grid container justify="center" direction="row" spacing={3}>
        <Grid item container xs={12} sm={12} justify="space-between">
          <Title value={Resource.smsTitle} />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={12} justify="center">
        <SmsDisclaimer
          hidden={showCircleProgressReady || showErrorAlert}
          homePhone={context.user.homePhone}
        />
        <Spinner hidden={!showCircleProgressReady} />
        <Grid item container xs={12} sm={12} justify="space-between">
        <Grid item container xs={12} sm={12} justify="center" >
            <SmsBox
              hidden={!context.user.recaptchaChecked || showErrorAlert}
              onValidatedSms={onVerifyLoginProvider}
              onShowResendButton={onShowResendButton}
              onShowCancelButton={onShowCancelButton}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <AlertUserAlreadyLinked userAlreadyLinked={context.user.userAlreadyLinked && !showErrorAlert} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <AlertFailFlow showErrorAlert={showErrorAlert}/>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={12} justify="center">
         <Recaptcha hidden={
          showCircleProgressReady ||
          context.user.recaptchaChecked ||
          showErrorAlert
          }
         />
        </Grid>
        <Grid item container xs={12} sm={12} justify="center">
          <ResendButton hidden={!showResendButton || showErrorAlert} onClick={onResend} />
        </Grid>
        <Grid item container xs={12} sm={12} justify="center">
          <SuccessButton hidden={!showSuccessButton} onClick={onSuccess} />
        </Grid>
        <Grid item container xs={12} sm={12} justify="center">
          <CancelButton hidden={!showCancelButton || showErrorAlert} onClick={onCancel} />
        </Grid>
        {
          (showResendButton || showErrorAlert) && (
            <Grid item container xs={12} sm={12} justify="center">
              <EmailButton onClick={onVerifyEmail} />
            </Grid>
          )
        }
      </Grid>
      <footer>
      </footer>
    </Container>
  )
}

export default Sms
