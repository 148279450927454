import { Button } from '@material-ui/core'
import React, { memo } from 'react'

import Resource from '../common/Resource'

interface SuccessButtonProps {
  hidden: boolean
  onClick: any
}

const SuccessButton = (props: SuccessButtonProps) => {
  return (
    <div className="success" hidden={props.hidden}>
    <Button color="primary" className="success-button" onClick={props.onClick} >
      {Resource.successButton}
    </Button>
  </div>
  )
}

export default memo(SuccessButton)
