import React, { memo } from 'react'
import Resource from '../common/Resource'
import { formatPhone } from '../common/StringFormat'

const SmsDisclaimer = (props: { homePhone: string; hidden: boolean }) => {
  const number = formatPhone(props.homePhone)
  const disclaimer = (
        <div className="disclaimer">
            {Resource.smsDisclaimer}
            <strong> {number} </strong>
        </div>
  )
  return props.hidden ? <div/> : disclaimer
}

export default memo(SmsDisclaimer)
