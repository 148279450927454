class BusService {
  onDispatchCancelEvent () {
    const { REACT_APP_CANCEL_EVENT_TYPE } = process.env
    document
      .getElementById('root')
      ?.dispatchEvent(new Event(REACT_APP_CANCEL_EVENT_TYPE || 'validOff'))
  }

  onDispatchSuccessEvent = () => {
    const {
      REACT_APP_SUCCESS_EVENT_TYPE,
      REACT_APP_SUCCESS_EVENT_TYPE_TIMEOUT
    } = process.env
    setTimeout(() => {
      document
        .getElementById('root')
        ?.dispatchEvent(new Event(REACT_APP_SUCCESS_EVENT_TYPE || 'validOn'))
    }, Number(REACT_APP_SUCCESS_EVENT_TYPE_TIMEOUT))
  }
}

export default new BusService() as BusService
