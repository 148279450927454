/* eslint-disable no-unused-vars */
import React, { memo } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const Spinner = (props: { hidden: boolean }) => {
  return (
        <div hidden={props.hidden}>
            <CircularProgress/>
        </div>
  )
}

export default memo(Spinner)
