import IUserModel from '../models/IUserModel'

const USER_CACHE = 'user'

class CacheService {
     get = (): IUserModel => {
       const data = localStorage.getItem(USER_CACHE) || ''
       try {
         return JSON.parse(data) as IUserModel
       } catch (error) {
         return {} as IUserModel
       }
     }

     set = (data: IUserModel) => {
       if (data) {
         localStorage.setItem(USER_CACHE, JSON.stringify(data))
       }
     }
}

export default new CacheService() as CacheService
