import React, { useContext, useEffect, useState } from 'react'
import ReactCodeInput from 'react-verification-code-input'

import { UserContext } from '../contexts/UserContext'
import { FirebaseCode } from '../common/FirebaseCode'
import Resource from '../common/Resource'

import Timer from './Timer'
import InputAlert from './InputAlert'

interface SmsProps {
    hidden: boolean;
    onValidatedSms: Function;
    onShowResendButton: Function;
    onShowCancelButton: Function;
}

const SmsBox = (props: SmsProps) => {
  const reactCodeInputLength = 6
  const context = useContext(UserContext)
  const [notification, setNotification] = useState('')
  const [notificationStyle, setNotificationStyle] = useState('')
  const [retryCount, setRetryCount] = useState(0)
  const [numberOfFieldsChanged, setNumberOfFieldsChanged] = useState(0)
  const [successConfirmation, setSuccessConfirmation] = useState(false)
  const [showNotification, setShowNotification] = useState(false)

  const onExpireSms = () => {
    const flags = { ...context.user }
    flags.isSmsExpired = true
    context.setFlags(flags)
    setShowNotification(true)
    setNotification(Resource.invalidReactCodeInputExpireMessage)
    props.onShowResendButton()
  }

  const onFailFlow = (error: any) => {
    switch (error.code) {
      case FirebaseCode.INVALID_CODE: {
        setNotification(Resource.invalidReactCodeInputMessage)
        if (retryCount < 5) {
          setRetryCount((oldNumber) => oldNumber + 1)
        } else {
          props.onShowCancelButton()
        }
        break
      }
      case FirebaseCode.CREDENTIAL_ALREADY_IN_USE: {
        setNotification(Resource.credentialAlreadyInUse)
        props.onShowCancelButton()
        break
      }
      case FirebaseCode.PROVIDER_ALREADY_LINKED: {
        setNotification(Resource.userAlreadyLinked)
        props.onShowCancelButton()
        break
      }
      default: {
        props.onShowCancelButton()
        break
      }
    }
  }

  const onLockBox = (code: string) => {
    context.user.confirmationResult
      ?.confirm(code)
      .then(() => {
        setSuccessConfirmation(true)
        setShowNotification(false)
        setNotificationStyle(Resource.successReactCodeInputStyle)
        props.onValidatedSms()
      })
      .catch((error: any) => {
        setShowNotification(true)
        onFailFlow(error)
      })
  }

  const onChange = () => {
    setNumberOfFieldsChanged((oldNumber) => oldNumber + 1)
  }

  useEffect(() => {
    if ((numberOfFieldsChanged >= reactCodeInputLength) || context.user.isSmsExpired) {
      const notificationStyle = showNotification
        ? Resource.invalidReactCodeInputStyle
        : ''
      setNotificationStyle(notificationStyle)
    }
  }, [showNotification, numberOfFieldsChanged])

  return (
    <div className="box" hidden={props.hidden}>
         <ReactCodeInput
                className={notificationStyle}
                title={Resource.titleReactuInput}
                type="number"
                fields={reactCodeInputLength}
                onChange={onChange}
                onComplete={onLockBox}
                disabled={context.user.isSmsExpired || successConfirmation}
            />
          <div className="timer-box">
          <InputAlert show={showNotification} description={notification} />
        <Timer
          onHandle={onExpireSms}
          hidden={!context.user.recaptchaChecked}
          successConfirmation={successConfirmation} />
        </div>
    </div>
  )
}

export default SmsBox
