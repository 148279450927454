import React, { useEffect, useState } from 'react'
import Resource from '../common/Resource'

interface TimerProps {
  onHandle: Function
  hidden: boolean
  successConfirmation: boolean
}

interface ITimer {
    count: number,
    stop: boolean
}

const Timer = (props: TimerProps) => {
  const [secs, setSecs] = useState<ITimer>({ count: 60, stop: false })

  const dec = (timer: ITimer) => {
    timer.count = timer.count - 1
    setSecs(timer)
  }
  const stop = (timer: ITimer) => {
    timer.stop = true
    setSecs(timer)
    if (!props.successConfirmation) { props.onHandle() }
  }

  const triggerInit = (timer: ITimer) => secs.count > 0 && setTimeout(() => dec(timer), 1000)
  const triggerStop = (timer: ITimer) => (secs.count === 0 || props.successConfirmation) && stop(timer)

  useEffect(() => {
    if (!secs.stop && !props.hidden) {
      const timer = { ...secs } as ITimer
      triggerInit(timer)
      triggerStop(timer)
    }
  }, [props, secs])

  return (
    <div className="timer ubuntu" hidden={props.hidden || props.successConfirmation}>
    {Resource.timerDescription}
    <strong style={{ color: '#1E5BC6' }}> {secs.count}s </strong>
  </div>
  )
}

export default Timer
