import { Button } from '@material-ui/core'
import React, { memo } from 'react'

import Resource from '../common/Resource'

interface ResendButtonProps {
  hidden: boolean
  onClick: any
}

const ResendButton = (props: ResendButtonProps) => {
  return (
    <div className="resend" hidden={props.hidden}>
      <Button color="primary" className="resend-button" onClick={props.onClick}>
        {Resource.resendButton}
      </Button>
    </div>
  )
}

export default memo(ResendButton)
