
class SmsConnectService {
  constructor () {
    const {
      REACT_APP_APIGEE_SMS_CONNECT_URL,
      REACT_APP_APIGEE_SMS_CONNECT_KEY
    } = process.env
    this._headers = new Headers()
    this._headers.append('Content-Type', 'application/json')
    this._headers.append('x-apikey', REACT_APP_APIGEE_SMS_CONNECT_KEY || '')
    this._smsConnectUrl = REACT_APP_APIGEE_SMS_CONNECT_URL || ''
  }

    private readonly _headers: Headers
    private readonly _smsConnectUrl: string

    public get = async (accessToken: string): Promise<string> => {
      this._headers.append('x-access-token', accessToken)
      const response = await fetch(`${this._smsConnectUrl}/api/v1/sms-connect`, {
        method: 'GET',
        headers: this._headers,
        redirect: 'follow'
      })
      const responseJson = await response.json()
      return responseJson.token
    }
}

export default new SmsConnectService() as SmsConnectService
