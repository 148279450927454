import { Button } from '@material-ui/core'
import React, { memo } from 'react'

import Resource from '../common/Resource'

interface CancelButtonProps {
  hidden: boolean
  onClick: any
}

const CancelButton = (props: CancelButtonProps) => {
  return (
    <div className="cancel" hidden={props.hidden}>
    <Button color="primary" className="cancel-button" onClick={props.onClick} >
      {Resource.cancelButton}
    </Button>
  </div>
  )
}

export default memo(CancelButton)
