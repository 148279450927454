import React, { memo } from 'react'

interface RecaptchaProps {
  hidden: boolean
}

const Recaptcha = (props: RecaptchaProps) => {
  return (
    <div
    className="disclaimer"
    id="recaptcha-container"
    hidden={props.hidden}
  ></div>
  )
}

export default memo(Recaptcha)
