import React, { memo } from 'react'

interface InputAlertProps {
  description: string
  show: boolean
}

const InputAlert = (props: InputAlertProps) => {
  return (
    <p hidden={!props.show} className="notification-error-code ubuntu">
    {props.description}
    </p>
  )
}

export default memo(InputAlert)
