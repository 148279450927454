import IUserModel from '../models/IUserModel'

class LoginService {
  constructor () {
    const {
      REACT_APP_APIGEE_CCI_LOGIN_KEY,
      REACT_APP_APIGEE_CCI_LOGIN_URL
    } = process.env
    this._headers = new Headers()
    this._headers.append('Content-Type', 'application/json')
    this._headers.append('x-apikey', REACT_APP_APIGEE_CCI_LOGIN_KEY || '')
    this._baseUrl = REACT_APP_APIGEE_CCI_LOGIN_URL || ''
  }

  private readonly _headers: Headers
  private readonly _baseUrl: string

    public get = async (accessToken: string): Promise<IUserModel> => {
      this._headers.append('Authorization', `Bearer ${accessToken}`)
      const response = await fetch(`${this._baseUrl}/v1/userinfo`, {
        method: 'GET',
        headers: this._headers,
        redirect: 'follow'
      })
      const users: IUserModel[] = await response.json()
      return { ...users[0], accessToken }
    }

    public verify = async (accessToken: string) => {
      try {
        const raw = JSON.stringify({ idToken: accessToken })
        const response = await fetch(`${this._baseUrl}/v1/login/emailVerified`, {
          method: 'POST',
          headers: this._headers,
          body: raw,
          redirect: 'follow'
        })
        return response.status === 200
      } catch (error) {
        return false
      }
    }
}

export default new LoginService() as LoginService
