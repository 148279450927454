import React, { createContext, useCallback, useState } from 'react'
import IUserModel from '../models/IUserModel'
import IUserContextModel from '../models/IUserContextModel'

export const UserContext = createContext<IUserContextModel>({
  user: {} as IUserModel,
  set: () => { },
  confirmationResult: () => { },
  setFlags: () => {}
})

const UserProvider = (props: any) => {
  const [user, setUser] = useState<IUserModel>({} as IUserModel)

  const set = useCallback((user: IUserModel) => {
    setUser(user)
  }, [user])

  const setFlags = useCallback((flags: { isSmsValidated: boolean, isSmsExpired: boolean }) => {
    setUser(old => {
      return { ...old, ...flags }
    })
  }, [])

  const confirmationResult = useCallback((result: any) => {
    setUser(old => {
      const user = { ...old }
      user.confirmationResult = result
      return user
    })
  }, [])

  return (
    <UserContext.Provider value={{ user, set, confirmationResult, setFlags }}>
      {props.children}
    </UserContext.Provider>
  )
}

export default UserProvider
