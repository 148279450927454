import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

class FirebaseService {
  private readonly _app: firebase.auth.Auth;

  constructor () {
    this._app = firebase
      .initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_CONFIG_DATA_BASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_CONFIG_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENT_ID
      })
      .auth()
  }

  public loadCredential = (
    token: string
  ): Promise<firebase.auth.UserCredential> => {
    return this._app.signInWithCustomToken(token)
  };

  public signOut = (callback: any) => {
    this._app.signOut().then(() => {
      window.location.reload()
    })
      .catch((error: any) => {
        callback(error)
      })
  }

  public linkWithPhoneNumber = (credential: firebase.auth.UserCredential | undefined, phoneNumber: string, recaptchaVerifier: firebase.auth.RecaptchaVerifier) => {
    return credential?.user?.linkWithPhoneNumber(phoneNumber, recaptchaVerifier)
  }

  public recaptchaVerifier = (
    callback: Function | undefined = undefined,
    domId: string = 'recaptcha-container',
    size: string = 'invisible'
  ) => {
    const parameters = {
      size: size,
      callback: callback
    }
    return new firebase.auth.RecaptchaVerifier(domId, parameters)
  };
}
export default new FirebaseService() as FirebaseService
